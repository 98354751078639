import axios from 'axios'; 
import Swal from 'sweetalert2';

axios.defaults.baseURL = window.location.protocol+"//"+window.location.host+"/api";
axios.defaults.headers.common['Authorization'] = "Bearer " +window.localStorage.getItem("jwt");

axios.interceptors.response.use((response) => response, (error) => {
  	Swal.fire({
		title: 'Error!',
		text: "Ooops! Something went wrong. Please contact your administrator.",
		icon: 'warning', 
	})
  	throw error;
});

export default axios;